import { CheckoutCart, UpdatableItemType } from 'src/Elements/Checkout/V2/types'
import { ProductId, PriceId, VariantId, OrderId, OrderNumber, LineItemId } from 'src/Elements/types'
import { cleanEmptyObjectKeys } from 'src/Elements/Utils/general'
import { computed, batched, map } from 'nanostores'
import { getSelectedVariant } from 'src/Elements/Order/AddToCart/addToCart'
import { get_available_variant } from 'yggdrasil-liquidjs/src/shared'

type OnMountCallback = (pcc: ProductCardComponent) => void

export type UpdatableItem = {
  variantName: string
  priceName: string
  direction: string
  cardDetails: {
    variantId: VariantId
    priceId: PriceId
    orderId?: OrderId
    lineItemId?: LineItemId
  }
}

export type UpdatableOrder = {
  id: OrderId
  number: OrderNumber
  type: UpdatableItemType
  updatableItems?: UpdatableItem[]
}

export type ExistingOrderProductCardDetail = {
  updatableOrders: UpdatableOrder[]
}

type ProductCardComponent = {
  // TODO: Move to CardComponent
  id: string
  element: HTMLElement
  render: () => void

  selectedOrderIndex: number
  selectedUpdatableCartItemIndex: number

  renderType: UpdatableItemType

  variantId: VariantId
  priceId: PriceId

  lineItemId: LineItemId
  orderId: OrderId

  selectType: 'single' | 'multiple' | 'quantity' | 'quantity-forced'
  quantity: number

  isChecked: boolean

  // TODO: define these types
  product: any
  variant: any
  selected_price: any
  variantValues: any
  valuesPositions: any

  skipMountOnRender?: boolean
} & ExistingOrderProductCardDetail

export type UpdatablePccState = Partial<
  {
    renderType: UpdatableItemType
    variantId: VariantId
    priceId: PriceId

    selectedOrderIndex: number
    selectedUpdatableCartItemIndex?: number

    selectType: string
    quantity: number

    // Checkout Cart specific changes:
    cartItemRenderType: UpdatableItemType
    cartItemLineItemId: LineItemId
    cartItemOrderId: OrderId
  } & ExistingOrderProductCardDetail
>

export const registerProductEventListeners = (pcc: ProductCardComponent): void => {
  registerUpdatableOrders(pcc)
  registerVariantEventListeners(pcc)
}

export const updateCardByProductIdState = (productId: string, data: UpdatablePccState): void => {
  const oldState: UpdatablePccState = productCardByProductId.get()[productId]
  const newState: UpdatablePccState = cleanEmptyObjectKeys({ ...oldState, ...data })

  productCardByProductId.setKey(productId, newState)
  updateProductCarousel(productId, newState.variantId)
}
export const updateProductCarousel = (productId: ProductId, variantId?: VariantId): void => {
  // NOTE(Zara): This behavior/integration between components seems to not belong to neither
  // of them. Probably we are missing a ortogonal feature that should be responsible for
  // Gluing components together.
  //
  // Something like action/event setting where the user can specify
  // thing like: "When the product select change the product/variant (event) update the product carousel with <id>
  // with the new variant (action)".
  //
  // And also this shoulnd't be a global script, instead it should be specific to the compoennts/elements
  // of each page. This would enable user to decide which component will update which carousel.
  // With the current implementation if we use more than one element that update the carousel.
  // They will compete with each other and the last one will win.
  document.dispatchEvent(
    new CustomEvent('ProductCarousel:Update', {
      detail: {
        productId,
        variantId: variantId,
      },
    })
  )
}

export const getProductCardByProductId = (): Record<string, UpdatablePccState> => {
  const productCardByProductId = globalThis.globalResourceData.products?.reduce((acc, product) => {
    const variant = get_available_variant(product)
    const price = variant.prices[0]

    acc[product.id] = {
      variantId: variant.id,
      priceId: price.id,
      quantity: 0,
    }
    return acc
  }, {})

  const productVariantWithNoBump = globalThis.globalResourceData.productVariantWithNoBump
  let selectedVariantId, selectedPriceId, selectedProductId
  if (productVariantWithNoBump) {
    selectedProductId = productVariantWithNoBump.product_id
    selectedVariantId = productVariantWithNoBump.id
    selectedPriceId = productVariantWithNoBump.prices?.[0].id
  }

  const productCardItem = productCardByProductId[selectedProductId]
  if (productCardItem) {
    const product = globalThis.globalResourceData.products?.find((p) => p.id === selectedProductId)
    const variant = product?.variants?.find((variant) => variant.id === selectedVariantId)
    if (variant) {
      const price = variant?.prices?.find((price) => price.id === selectedPriceId)
      if (price) {
        productCardByProductId[selectedProductId] = {
          variantId: variant.id,
          priceId: price.id,
          quantity: 1,
        }
      }
    }
  }

  return productCardByProductId
}

const productCardByProductId = map(getProductCardByProductId())

const sameItem = (v1, v2) =>
  v1.type == v2.type &&
  v1.line_item_id == v2.line_item_id &&
  v1.product_id == v2.product_id &&
  v1.variant_id == v2.variant_id &&
  v1.price_id == v2.price_id &&
  v1.quantity == v2.quantity

const calculateCart = (oldCheckoutCart: any, productCardByProductId: any) => {
  if (globalThis.globalResourceData.cart) return structuredClone(globalThis.globalResourceData.cart)
  let order_id
  const items = Object.entries(productCardByProductId)
    .filter(([, v]: any) => v.quantity > 0)
    .map(([productId, v]: any) => {
      order_id = order_id ?? v.cartItemOrderId
      return {
        type: v.cartItemRenderType,
        line_item_id: v.cartItemLineItemId,
        product_id: productId,
        variant_id: v.variantId,
        price_id: v.priceId,
        quantity: v.quantity,
      }
    })
  const newCheckoutCart = {
    order_id,
    items,
  }
  const isEqual =
    oldCheckoutCart.cart &&
    oldCheckoutCart.cart.order_id == newCheckoutCart.order_id &&
    oldCheckoutCart.cart.items.length == newCheckoutCart.items.length &&
    oldCheckoutCart.cart.items.every((oldItem, idx) => sameItem(oldItem, newCheckoutCart.items[idx]))

  oldCheckoutCart.cart = newCheckoutCart

  return isEqual ? oldCheckoutCart.cart : newCheckoutCart
}

// NOTE: We should investigate moving to batched instead of computing
// as cart updates can happen for two different products (e.g. radio button)
// with computed that would lead in two different events for computed listeners
export const buildProductSelectComputed = (): any => {
  const oldCheckoutCart = { cart: null }
  const computedCheckoutCart = computed<CheckoutCart, any>(productCardByProductId, (productCardByProductId: any) =>
    calculateCart(oldCheckoutCart, productCardByProductId)
  )
  return computedCheckoutCart
}

export const buildProductSelectBatched = (): any => {
  const oldCheckoutCart = { cart: null }
  const computedCheckoutCart = batched<CheckoutCart, any>(productCardByProductId, (productCardByProductId: any) =>
    calculateCart(oldCheckoutCart, productCardByProductId)
  )
  return computedCheckoutCart
}

export const anyUpdatableOrders = (): boolean => {
  return Object.values(productCardByProductId.get()).some((v: any) => v.updatableOrders)
}

export const registerOneTimeCardElementListeners = (
  productCardComponents: ProductCardComponent[],
  onMountCallback: OnMountCallback
): void => {
  productCardComponents.forEach((pcc) => {
    renderPcc(pcc, onMountCallback)
  })

  productCardByProductId.listen(() => {
    productCardComponents.forEach((pcc) => {
      renderPcc(pcc, onMountCallback)
    })
  })

  productCardComponents.forEach((pcc) => {
    const element = pcc.element
    element.addEventListener('click', (evt: Event) => {
      if (pcc.selectType == 'quantity') return
      const button = (evt.target as HTMLElement).closest('a')
      // NOTE: return if its, for example, a link from card description,
      // but not a bump add/remove button
      if (button && !button.closest('.elProductCardModernButton')) return

      const productId = pcc.product?.id
      if (pcc.selectType == 'single') {
        for (const otherPcc of productCardComponents) {
          if (otherPcc == pcc || !otherPcc.isChecked) continue
          updateCardByProductIdState(otherPcc.product.id, { quantity: 0 })
        }
        if (pcc.id.includes('Bump')) {
          if (pcc.isChecked) {
            updateCardByProductIdState(productId, { quantity: 0 })
          } else {
            updateCardByProductIdState(productId, { quantity: 1 })
          }
        } else {
          updateCardByProductIdState(productId, { quantity: 1 })
        }
      } else if (pcc.selectType == 'multiple') {
        if (pcc.isChecked) {
          updateCardByProductIdState(productId, { quantity: 0 })
        } else {
          updateCardByProductIdState(productId, { quantity: 1 })
        }
      }
    })
  })
}

export const registerEventListeners = (pcc: ProductCardComponent, onMountCallback: OnMountCallback): void => {
  registerPriceEventListeners(pcc)
  if (onMountCallback) onMountCallback(pcc)
  if (pcc.selectType == 'quantity') {
    registerQuantityEventListeners(pcc)
  }
}

const registerVariantEventListeners = (pcc: ProductCardComponent): void => {
  const element = pcc.element
  const variantSelects = element.querySelectorAll<HTMLSelectElement>('.elVariantSelector')

  variantSelects.forEach((select, index) => {
    select.addEventListener('click', (evt) => {
      evt.stopImmediatePropagation()
      evt.stopPropagation()
      evt.preventDefault()
    })

    select.addEventListener('change', (evt) => {
      evt.stopImmediatePropagation()
      evt.stopPropagation()
      evt.preventDefault()

      const newValues = [...variantSelects].map((e) => e.value)

      const selectedVariantId = getSelectedVariant(pcc, index, newValues)

      const newVariant = pcc.product.variants.find((v) => v.id == String(selectedVariantId))
      const newPrice = newVariant.prices[0]

      updateCardByProductIdState(pcc.product.id, {
        variantId: newVariant.id,
        priceId: newPrice.id,
      })
    })
  })
}

const registerUpdatableOrders = (pcc: ProductCardComponent): void => {
  const element = pcc.element
  const previousOrders = element.querySelectorAll('.elProductCardOrder')
  const newOrder = element.querySelector('.elProductCardNewOrder')

  previousOrders?.forEach((orderElement) => {
    const showOrderDetailsLink = orderElement.querySelector('.elProductCardShowOrderDetailsLink')
    const upgradeAndDowngradeSelector = orderElement.querySelector('[name="upgradeDowngrade"]')
    const orderIndex = Number(orderElement.getAttribute('data-order-index'))
    const order = pcc.updatableOrders[orderIndex]

    orderElement.addEventListener('click', (evt) => {
      evt.stopImmediatePropagation()
      evt.stopPropagation()
      evt.preventDefault()
      const productId = pcc.product.id
      const renderType = order.type
      const cardDetails = pcc.updatableOrders[orderIndex].updatableItems[0].cardDetails
      const updateCardNewState: UpdatablePccState = {
        selectedOrderIndex: orderIndex,
        selectedUpdatableCartItemIndex: 0,
        renderType,
        variantId: cardDetails.variantId,
        priceId: cardDetails.priceId,

        cartItemRenderType: renderType,
        cartItemOrderId: cardDetails.orderId,
        cartItemLineItemId: cardDetails.lineItemId,
      }
      updateCardByProductIdState(productId, updateCardNewState)
    })

    showOrderDetailsLink?.addEventListener('click', (evt) => {
      evt.stopImmediatePropagation()
      evt.stopPropagation()
      evt.preventDefault()
      globalThis.Checkout.store.selectedOrderDetailId.set(order.id)
    })

    upgradeAndDowngradeSelector?.addEventListener('click', (evt) => {
      evt.preventDefault()
      evt.stopImmediatePropagation()
      evt.stopPropagation()
    })

    upgradeAndDowngradeSelector?.addEventListener('change', (evt: Event) => {
      evt.stopImmediatePropagation()
      evt.stopPropagation()
      evt.preventDefault()
      const updatableItemIndex = Number((evt.target as HTMLSelectElement).value)
      const cardDetails = order.updatableItems[updatableItemIndex].cardDetails
      const updateCardNewState: UpdatablePccState = {
        selectedUpdatableCartItemIndex: updatableItemIndex,
        variantId: cardDetails.variantId,
        priceId: cardDetails.priceId,

        cartItemOrderId: cardDetails.orderId,
        cartItemLineItemId: cardDetails.lineItemId,
      }
      updateCardByProductIdState(pcc.product.id, updateCardNewState)
    })
  })

  newOrder?.addEventListener('click', () => {
    const variant = pcc.product.variants[0]
    const variantId = variant.id
    const priceId = variant.prices[0].id
    const productId = pcc.product.id

    const updateCardNewState: UpdatablePccState = {
      variantId,
      priceId,
      selectedOrderIndex: -1,
      selectedUpdatableCartItemIndex: 0,

      cartItemRenderType: undefined,
      cartItemOrderId: undefined,
      cartItemLineItemId: undefined,
    }
    updateCardByProductIdState(productId, updateCardNewState)
  })
}

const registerPriceEventListeners = (pcc: ProductCardComponent): void => {
  const element = pcc.element
  const variantPriceSelector = element.querySelector('[name="variant_price"]')
  variantPriceSelector?.addEventListener('click', (evt) => {
    evt.preventDefault()
    evt.stopImmediatePropagation()
    evt.stopPropagation()
  })

  variantPriceSelector?.addEventListener('change', (evt) => {
    evt.stopImmediatePropagation()
    evt.stopPropagation()
    evt.preventDefault()
    const newPriceId = Number((evt.target as HTMLSelectElement).value) as PriceId
    updateCardByProductIdState(pcc.product.id, { priceId: newPriceId })
  })
}

const registerQuantityEventListeners = (pcc: ProductCardComponent): void => {
  const element = pcc.element
  const [minusButton, plusButton] = element.querySelectorAll('.elProductInputControls button')
  const input = element.querySelector<HTMLInputElement>('.elProductInputControls input')
  minusButton.addEventListener('click', (evt) => {
    evt.preventDefault()
    if (pcc.quantity === 0) return
    const newQuantity = pcc.quantity - 1
    updateCardByProductIdState(pcc.product.id, { quantity: newQuantity })
  })
  plusButton.addEventListener('click', (evt) => {
    evt.preventDefault()
    const newQuantity = pcc.quantity + 1
    updateCardByProductIdState(pcc.product.id, { quantity: newQuantity })
  })
  input.addEventListener('blur', (evt: Event) => {
    const newQuantity = parseInt((evt.target as HTMLInputElement).value)
    if (newQuantity == 0 || isNaN(newQuantity)) {
      updateCardByProductIdState(pcc.product.id, { quantity: 0 })
    } else {
      updateCardByProductIdState(pcc.product.id, { quantity: newQuantity })
    }
  })
}

export const renderAndMount = (pcc: ProductCardComponent, onMountCallback: OnMountCallback): void => {
  pcc.render()
  if (pcc.skipMountOnRender) return
  registerEventListeners(pcc, onMountCallback)
}

const renderPcc = (pcc: ProductCardComponent, onMountCallback: OnMountCallback): void => {
  const updates: UpdatablePccState = productCardByProductId.get()[pcc.product.id]

  let newSelectType
  if (anyUpdatableOrders()) {
    newSelectType = 'single'
  } else if (!pcc.product.bump) {
    newSelectType = globalThis.globalResourceData.product_quantity_type ?? 'single'
  } else {
    newSelectType = pcc.selectType
  }

  if (
    pcc.renderType == updates.renderType &&
    pcc.variant.id == updates.variantId &&
    pcc.selected_price.id == updates.priceId &&
    pcc.quantity == updates.quantity &&
    pcc.selectType == newSelectType &&
    pcc.selectedOrderIndex == updates.selectedOrderIndex &&
    pcc.selectedUpdatableCartItemIndex == updates.selectedUpdatableCartItemIndex &&
    pcc.updatableOrders == updates.updatableOrders
  )
    return

  pcc.renderType = updates.renderType
  pcc.variant = globalThis.globalResourceData.variantsById[updates.variantId]
  pcc.selected_price = globalThis.globalResourceData.pricesById[updates.priceId]
  pcc.quantity = updates.quantity
  pcc.selectType = newSelectType
  pcc.selectedOrderIndex = updates.selectedOrderIndex
  pcc.selectedUpdatableCartItemIndex = updates.selectedUpdatableCartItemIndex
  pcc.updatableOrders = updates.updatableOrders

  pcc.isChecked = updates.quantity > 0
  if (pcc.isChecked) {
    pcc.element.classList.add('elProductSelected')
  } else {
    pcc.element.classList.remove('elProductSelected')
  }

  renderAndMount(pcc, onMountCallback)
}
